import React, { useEffect, useState } from 'react';
import download from '../assets/images/download.png';
import cricket from '../assets/images/cricket.png';
function MainSection(){

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallPromptVisible, setIsInstallPromptVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Save the event so it can be triggered later
      setDeferredPrompt(e);
      // Show the install button or banner
      setIsInstallPromptVisible(true);
    };

    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleDownloadClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        // Reset the deferred prompt variable
        setDeferredPrompt(null);
      });
    } else {
      // If the PWA prompt isn't available, you can navigate to an alternative download link
      // window.location.href = 'URL to your APK or download page';
    }
  };


    return(
        <div className="main-download">
        <div className='container'>
          <div className="wallet2">
          <div className="row align-items-center">
          <div className="col-md-8 col-lg-5">
          <div className="text">
            <h2 className='hh1 mb-3'>Download Betpro Wallet</h2>
            <h2 className='hh2 mb-3'>On your phone today</h2>
          
            <p>
              Betpro Wallet APP is an Android earning app that provides the best platform for users to start earning money right away.
              The APP offers users the widest range of sports and games collection to instantly bet and win big. It is only providing
              services for users who are living in Pakistan.
            </p>
            <p>
              Betpro Wallet APP is a legal and safe platform for those who are interested to win big with a small investment.
            </p>
           
            <button className="btn-download mb-3" onClick={handleDownloadClick}> <img src={download} alt="Download" className="btn-icon" />Download Now</button>
            </div>
          </div>
  
          <div className="col-md-4 col-lg-7">
          <img src={cricket} alt="Top Cricketers - Betpro Wallet Sports Betting Platform"  className="cricket-image" />
          
          </div>
        </div> </div>
        </div>
        </div>
    );

}
export default MainSection;