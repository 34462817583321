
import React, { useEffect, useState } from 'react';

const NotFoundPage = () => {
    return (
        <div>
            <h1>404 Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <a href='/'>Return to Home page</a>
        </div>
    );
}

export default NotFoundPage;
