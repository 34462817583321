import React from 'react';
import logo from '../assets/images/logo.png';
import download from '../assets/images/download.png';



function Header() {
  return (
    <header className="App-header bg-dark">
    <div className="container d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <img src={logo} width="30" height="30" className="mr-2" alt="Betpro Wallet logo" />
        <h3 className="text-white mb-0">Betpro Wallet</h3>
      </div>
      {/* <button className="btn btn-app d-flex align-items-center">
        <img src={download} alt="Download" className="btn-icon mr-2" />
        <span className="text-black dn_btn_txt">Betpro App</span>
      </button> */}
    </div>
  </header>
  );
}

export default Header;
