import React from "react";
import { Nav } from "react-bootstrap";
import betpro from "../assets/images/betpro.png";

import { FaHome, FaBookOpen, FaMoneyBillAlt, FaUser } from "react-icons/fa";
import { FaClockRotateLeft } from "react-icons/fa6";
import { SiBitcoinsv } from "react-icons/si";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
const Navigation = () => {
  const location = useLocation();
  const currentRoute = location.pathname.toLowerCase();
  // console.log("current route,", currentRoute);
  return (
    <div className="main-wrapper-nav">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Nav className="me-auto cus-navb">
              <div
                className={`image col-2 ${
                  currentRoute.includes("home") ? "link-active" : ""
                }`}
              >
                <Nav.Link href="/home">
                  <FaHome className="icon2" size={35} />
                  <span>Home</span>
                </Nav.Link>
              </div>
              <div
                className={`image col-2 ${
                  currentRoute.includes("withdraw") ? "link-active" : ""
                }`}
              >
                <Nav.Link href="https://bpexch.com/Users/Login">
                  {" "}
                  {/* <img src={betpro} alt="betpro img" /> */}
                  <SiBitcoinsv className="icon2" size={35}  />
                  <span>BetPro</span>
                </Nav.Link>
              </div>
              <div
                className={`image col-2 ${
                  currentRoute.includes("deposit") ? "link-active" : ""
                }`}
              >
                <Nav.Link href="/deposit">
                  {" "}
                  <FaMoneyBillAlt className="icon2" size={35} />
                  <span>Deposit</span>
                </Nav.Link>
              </div>
              <div
                className={`image col-lg-2 col-md-2 col-sm-3 col-3 ${
                  currentRoute.includes("transactions") ? "link-active" : ""
                }`}
              >
                <Nav.Link href="/transactions">
                  {" "}
                  <FaClockRotateLeft className="icon2" size={35} />
                  <span>Transactions</span>
                </Nav.Link>
              </div>
              <div
                className={`image col-2 ${
                  currentRoute.includes("profile") ? "link-active" : ""
                }`}
              >
                <Nav.Link href="/profile">
                  {" "}
                  <FaUser className="icon2" size={35} />
                  <span>Profile</span>
                </Nav.Link>
              </div>
            </Nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
