import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

const Notificationpage = () => {
  return (
    <div>

       <div className="login-wrapper">
        <div className="main-login">
          <div className="container">
            <form method="post" action=" " >
              <div class="row align-items-top">
                <div class="col-md-12">
                  <div class="left-login-box2">
                    <div class="fieldset">
                      <div class="title mb-5">
                        <div className="account-login mt-5">
                        <h3 className="text-center mb-3">Sign in failed </h3>
                         <p className="mb-3">Your username or password is incorrect.Please try again.</p>
                   <hr />
                   <p className="text-center">Ok</p>
                       
                        </div>      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Notificationpage
