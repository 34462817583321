// import React, { useEffect, useState } from 'react';
// import { BrowserRouter as Router, Route, Routes,Navigate, useNavigate  } from 'react-router-dom';
// import HomePage from './pages/Homepage';
// import Signuppage from './pages/Signuppage';
// import Accountpage from './pages/Accountpage';
// import Activepage from './pages/Activepage';
// import Transactionspage from './pages/Transactionspage';
// import Forgetpage from './pages/Forgetpage';
// import Notificationpage from './pages/Notificationpage';
// import Depositpage from './pages/Depositpage';
// import Withdrawpage from './pages/Withdrawpage';
// import NotFoundPage from './pages/NotFoundPage'; 



// const App = () => {
//   const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
//   const navigate = useNavigate();
//   useEffect(() => {
//     const user = localStorage.getItem('user');
//     if (user) {
//       setIsUserLoggedIn(true);
//       navigate('/active');
//     } else {
//       navigate('/signup');
//     }
//   }, []);
//   return (
//     <Router>
//       <div> 
//         <Routes>
//           {!isUserLoggedIn ? (
//             <>
//                <Route exact path="/" element={<HomePage />} />
//                <Route path="/signup" element={<Signuppage />} />
//             </>
//           ) : (
//             <>
//                <Route path="/account" element={<Accountpage />} />
//           <Route path="/active" element={<Activepage />} />
//           <Route path="/transactions" element={<Transactionspage />} />
//           <Route path="/forget" element={<Forgetpage />} />
//           <Route path="/notification" element={<Notificationpage />} />
//           <Route path="/deposit" element={<Depositpage />} />
//           <Route path="/withdraw" element={<Withdrawpage />} />

//           <Route path="*" element={<NotFoundPage />} />
//             </>
//           )}
//         </Routes>
//       </div>
//     </Router>
//   );
// }
// export default App;


import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Loginpage from './pages/Loginpage';
import Signuppage from './pages/Signuppage';
import Homepage from './pages/Homepage';
import ProfilePage from './pages/ProfilePage';
import Transactionspage from './pages/Transactionspage';
import Forgetpage from './pages/Forgetpage';
import Notificationpage from './pages/Notificationpage';
import Depositpage from './pages/Depositpage';
import Withdrawpage from './pages/Withdrawpage';
import NotFoundPage from './pages/NotFoundPage';

const App = () => {


  useEffect(() => {
    const preventZoom = (e) => {
      if (e.ctrlKey && (e.key === '+' || e.key === '-' || e.key === '0')) {
        e.preventDefault();
      }
    };

    const preventWheelZoom = (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', preventZoom);
    window.addEventListener('wheel', preventWheelZoom, { passive: false });

    return () => {
      window.removeEventListener('keydown', preventZoom);
      window.removeEventListener('wheel', preventWheelZoom);
    };
  }, []);



  return (
    <div> 
      <Routes>
            <Route exact path="/" element={<Loginpage />} />
            <Route path="/signup" element={<Signuppage />} />
            <Route path="/home" element={<Homepage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/transactions" element={<Transactionspage />} />
            <Route path="/forget" element={<Forgetpage />} />
            <Route path="/notification" element={<Notificationpage />} />
            <Route path="/deposit" element={<Depositpage />} />
            <Route path="/withdraw" element={<Withdrawpage />} />
            <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
