import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

const Forgetpage = () => {
  return (
    <div>
             <Header />

      <div className="login-wrapper">
        <div className="main-login">
          <div className="container">
            <form method="post" action=" " >
              <div class="row align-items-top">
                <div class="col-md-12">
                  <div class="left-login-box2">
                    <div class="fieldset">
                      <div class="title mb-5">
                        <div className="account-login mt-5">
                        <h4 className="text-center mb-3">Forget Password?</h4>
                         <p>You will receive instructions for resetting your password.</p>
                         <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="inputs field-container">
                            <input type="text" className=" mt-3 mb-4" placeholder="Email or phone number" />
                            <button type="submit" className="btn-signin mb-3">Send my Password</button>
                          </div>
                        </div>
                      </div>
                       
                        </div>      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
             <Footer />
    </div>
  )
}

export default Forgetpage
